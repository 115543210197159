<template>
    <div>
        <v-card class="pt-4">
            <v-card-title>
                <div class="pl-4">Copy Trades Verification</div>
                <div style="position: absolute; right: 0; padding-right: 20px">
                    <div style="display: inline-table">
                        <v-btn
                            small
                            color="default"
                            :loading="loading"
                            :disabled="copyTradeConfig.length === 0"
                            @click="onRefresh"
                            ><v-icon>mdi-refresh</v-icon></v-btn
                        >
                        <v-menu offset-y dense>
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                    small
                                    color="default"
                                    dark
                                    :disabled="copyTradeConfig.length === 0"
                                    v-bind="attrs"
                                    v-on="on"
                                >
                                    {{ apiTimer == 0 ? "off" : apiTimer + "s" }}
                                </v-btn>
                            </template>
                            <v-list dense>
                                <v-list-item
                                    v-for="item in timerOption"
                                    :key="item.value"
                                    v-on:click="changeTimer(item.value)"
                                >
                                    <v-list-item-title>{{
                                        item.name
                                    }}</v-list-item-title>
                                </v-list-item>
                            </v-list>
                        </v-menu>
                        <v-progress-linear
                            v-show="apiTimer != 0"
                            style="position: relative; height: 3px; bottom: 3px"
                            :value="apiTimerValue"
                        ></v-progress-linear>
                    </div>
                    <v-btn @click="showConfigDialog = true" small dense
                        ><v-icon>mdi-cog</v-icon></v-btn
                    >
                </div>
            </v-card-title>
            <v-container fluid>
                <v-row dense>
                    <v-col
                        lg="12"
                        md="12"
                        xs="12"
                        cols="12"
                        v-for="item in expansionPanelList"
                    >
                        <v-expansion-panels
                            multiple
                            v-model="item['isExpanded']"
                        >
                            <v-expansion-panel>
                                <v-expansion-panel-header
                                    style="padding: 0px 26px; min-height: 30px"
                                    ><div>
                                        <v-tooltip top>
                                            <template
                                                v-slot:activator="{ on, attrs }"
                                            >
                                                <b v-bind="attrs" v-on="on">{{
                                                    item["source"]
                                                }}</b>
                                            </template>
                                            <span>Source Login</span>
                                        </v-tooltip>

                                        <span
                                            ><v-icon
                                                small
                                                class="mb-1 pl-2 pr-2"
                                                >mdi-arrow-right</v-icon
                                            ></span
                                        >
                                        <v-tooltip top>
                                            <template
                                                v-slot:activator="{ on, attrs }"
                                            >
                                                <b
                                                    v-bind="attrs"
                                                    v-on="on"
                                                    class="mr-1"
                                                    >{{ item["target"] }}</b
                                                >
                                            </template>
                                            <span>Target Login</span>
                                        </v-tooltip>

                                        <span
                                            v-if="
                                                item['targetTrades'].length !==
                                                item['sourceTrades'].length
                                            "
                                        >
                                            (<span style="color: red">{{
                                                item["sourceTrades"].length
                                            }}</span>
                                            <v-icon small class="mb-1 pl-1 pr-1"
                                                >mdi-arrow-right</v-icon
                                            >
                                            <span style="color: red">{{
                                                item["targetTrades"].length
                                            }}</span></span
                                        ><span v-else>
                                            ({{ item["sourceTrades"].length }}
                                            <v-icon small class="mb-1 pl-1 pr-1"
                                                >mdi-arrow-right</v-icon
                                            >
                                            {{
                                                item["targetTrades"].length
                                            }}</span
                                        >
                                        records)
                                        <span
                                            v-if="
                                                item.sourceTrades.length !==
                                                item.targetTrades.length
                                            "
                                        >
                                            <v-tooltip top>
                                                <template
                                                    v-slot:activator="{
                                                        on,
                                                        attrs,
                                                    }"
                                                >
                                                    <v-icon
                                                        small
                                                        class="mb-1 pl-1"
                                                        color="red"
                                                        v-bind="attrs"
                                                        v-on="on"
                                                        >mdi-bell</v-icon
                                                    >
                                                </template>
                                                <span
                                                    >Warning: Orders from source
                                                    and target logins do not
                                                    match.</span
                                                >
                                            </v-tooltip></span
                                        >
                                    </div></v-expansion-panel-header
                                >
                                <v-expansion-panel-content>
                                    <v-row dense>
                                        <v-col lg="6" md="6" xs="12">
                                            <h4 style="padding: 15px">
                                                Source: {{ item.source }}
                                            </h4>
                                            <v-data-table
                                                dense
                                                :header-props="{
                                                    sortIcon: null,
                                                }"
                                                :headers="tableHeader"
                                                :items="item['sourceTrades']"
                                                loading-text="Loading... Please wait"
                                                :items-per-page="10"
                                                fixed-header
                                                sort-desc
                                                sort-by="open_time"
                                            >
                                                <template
                                                    v-slot:item="{ item }"
                                                >
                                                    <tr
                                                        :class="
                                                            item.relation +
                                                            item.ticket
                                                        "
                                                        @mouseover="
                                                            onSourceOver(item)
                                                        "
                                                        @mouseout="
                                                            onSourceOut(item)
                                                        "
                                                    >
                                                        <td column="ticket">
                                                            {{ item.ticket }}
                                                        </td>
                                                        <td column="cmd">
                                                            {{
                                                                item.cmd.toString() ===
                                                                "0"
                                                                    ? "Buy"
                                                                    : item.cmd.toString() ===
                                                                      "1"
                                                                    ? "Sell"
                                                                    : item.cmd
                                                            }}
                                                        </td>
                                                        <td
                                                            column="volume"
                                                            align="right"
                                                        >
                                                            {{
                                                                (
                                                                    item.volume /
                                                                    100
                                                                ).toFixed(2)
                                                            }}
                                                        </td>
                                                        <td column="open_time">
                                                            {{
                                                                formatTimestamp(
                                                                    item.open_time *
                                                                        1000
                                                                )
                                                            }}
                                                        </td>
                                                        <td
                                                            column="open_price"
                                                            align="right"
                                                        >
                                                            {{
                                                                decimalPlaceCorrection(
                                                                    item.symbol,
                                                                    item.open_price
                                                                )
                                                            }}
                                                        </td>
                                                        <td column="reason">
                                                            {{
                                                                reasonList[
                                                                    item.reason
                                                                ]
                                                            }}
                                                        </td>
                                                        <td column="comment">
                                                            <div
                                                                v-if="
                                                                    item.comment
                                                                        .length <
                                                                    22
                                                                "
                                                            >
                                                                {{
                                                                    item.comment
                                                                }}
                                                            </div>
                                                            <div v-else>
                                                                <v-tooltip top>
                                                                    <template
                                                                        v-slot:activator="{
                                                                            on,
                                                                            attrs,
                                                                        }"
                                                                        ><div
                                                                            v-bind="
                                                                                attrs
                                                                            "
                                                                            v-on="
                                                                                on
                                                                            "
                                                                        >
                                                                            {{
                                                                                item.comment.substring(
                                                                                    0,
                                                                                    20
                                                                                )
                                                                            }}<span
                                                                                ><v-icon
                                                                                    small
                                                                                    >mdi-dots-horizontal</v-icon
                                                                                ></span
                                                                            >
                                                                        </div>
                                                                    </template>
                                                                    <span
                                                                        >{{
                                                                            item.comment
                                                                        }}
                                                                    </span>
                                                                </v-tooltip>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                </template>
                                            </v-data-table>
                                        </v-col>
                                        <v-col lg="6" md="6" xs="12">
                                            <h4 style="padding: 15px">
                                                Target: {{ item.target }}
                                            </h4>
                                            <v-data-table
                                                dense
                                                :header-props="{
                                                    sortIcon: null,
                                                }"
                                                :headers="tableHeader"
                                                :items="item['targetTrades']"
                                                loading-text="Loading... Please wait"
                                                :items-per-page="10"
                                                fixed-header
                                                sort-desc
                                                sort-by="open_time"
                                            >
                                                <template v-slot:item="{ item }"
                                                    ><tr
                                                        :class="
                                                            item.relation +
                                                            (
                                                                item.comment ||
                                                                ''
                                                            ).split('-')[1]
                                                        "
                                                        @mouseover="
                                                            onTargetOver(item)
                                                        "
                                                        @mouseout="
                                                            onTargetOut(item)
                                                        "
                                                    >
                                                        <td column="ticket">
                                                            {{ item.ticket }}
                                                        </td>
                                                        <td column="cmd">
                                                            {{
                                                                item.cmd.toString() ===
                                                                "0"
                                                                    ? "Buy"
                                                                    : item.cmd.toString() ===
                                                                      "1"
                                                                    ? "Sell"
                                                                    : item.cmd
                                                            }}
                                                        </td>
                                                        <td
                                                            column="volume"
                                                            align="right"
                                                        >
                                                            <v-tooltip
                                                                top
                                                                v-if="
                                                                    !sufficientTrade(
                                                                        item
                                                                    )
                                                                "
                                                            >
                                                                <template
                                                                    v-slot:activator="{
                                                                        on,
                                                                        attrs,
                                                                    }"
                                                                    ><div
                                                                        v-bind="
                                                                            attrs
                                                                        "
                                                                        v-on="
                                                                            on
                                                                        "
                                                                        style="
                                                                            color: red;
                                                                        "
                                                                    >
                                                                        {{
                                                                            (
                                                                                item.volume /
                                                                                100
                                                                            ).toFixed(
                                                                                2
                                                                            )
                                                                        }}
                                                                    </div>
                                                                </template>
                                                                <span
                                                                    >Volume of
                                                                    this trade
                                                                    failed to
                                                                    satisfy its
                                                                    copy trades
                                                                    config
                                                                </span>
                                                            </v-tooltip>

                                                            <div v-else>
                                                                {{
                                                                    (
                                                                        item.volume /
                                                                        100
                                                                    ).toFixed(2)
                                                                }}
                                                            </div>
                                                        </td>
                                                        <td column="open_time">
                                                            {{
                                                                formatTimestamp(
                                                                    item.open_time *
                                                                        1000
                                                                )
                                                            }}
                                                        </td>
                                                        <td
                                                            column="open_price"
                                                            align="right"
                                                        >
                                                            {{
                                                                decimalPlaceCorrection(
                                                                    item.symbol,
                                                                    item.open_price
                                                                )
                                                            }}
                                                        </td>
                                                        <td column="reason">
                                                            {{
                                                                reasonList[
                                                                    item.reason
                                                                ]
                                                            }}
                                                        </td>
                                                        <td column="comment">
                                                            <div
                                                                v-if="
                                                                    item.comment
                                                                        .length <
                                                                    22
                                                                "
                                                            >
                                                                {{
                                                                    item.comment
                                                                }}
                                                            </div>
                                                            <div v-else>
                                                                <v-tooltip top>
                                                                    <template
                                                                        v-slot:activator="{
                                                                            on,
                                                                            attrs,
                                                                        }"
                                                                        ><div
                                                                            v-bind="
                                                                                attrs
                                                                            "
                                                                            v-on="
                                                                                on
                                                                            "
                                                                        >
                                                                            {{
                                                                                item.comment.substring(
                                                                                    0,
                                                                                    20
                                                                                )
                                                                            }}<span
                                                                                ><v-icon
                                                                                    small
                                                                                    >mdi-dots-horizontal</v-icon
                                                                                ></span
                                                                            >
                                                                        </div>
                                                                    </template>
                                                                    <span
                                                                        >{{
                                                                            item.comment
                                                                        }}
                                                                    </span>
                                                                </v-tooltip>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                </template>
                                            </v-data-table>
                                        </v-col>
                                    </v-row>
                                </v-expansion-panel-content>
                            </v-expansion-panel>
                        </v-expansion-panels>
                    </v-col>
                </v-row>
            </v-container>
        </v-card>
        <v-dialog v-model="showConfigDialog" width="1200" :persistent="true">
            <v-card class="pt-0">
                <v-app-bar
                    flat
                    color="rgba(0, 0, 0, 0)"
                    style="background: rgb(26, 57, 88)"
                >
                    <v-toolbar-title class="text-h6 white--text pl-0"
                        >Copy Trades Config</v-toolbar-title
                    >

                    <v-spacer></v-spacer>

                    <v-btn color="white" icon @click="showConfigDialog = false">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-app-bar>
                <v-data-table
                    dense
                    :header-props="{
                        sortIcon: null,
                    }"
                    :headers="filteredHeader"
                    :items="copyTradeConfig"
                    :items-per-page="-1"
                    fixed-header
                >
                    <template v-slot:item="{ item }">
                        <tr>
                            <td column="id" class="fixCol_first">
                                {{ item.id }}
                            </td>
                            <td column="server_id" class="fixCol_second">
                                {{ getServerName(item.server_id) }}
                            </td>
                            <td column="SourceLogin" class="fixCol_third">
                                {{ item.SourceLogin }}
                            </td>
                            <td column="TargetLogin" class="fixCol_forth">
                                {{ item.TargetLogin }}
                            </td>

                            <td column="VerifyTimeRange">
                                {{ item.VerifyTimeRange }}
                            </td>
                            <td column="PartialFollowerTimeRange">
                                {{ item.PartialFollowerTimeRange }}
                            </td>
                            <td column="MobileRatio">
                                {{ item.MobileRatio }}%
                            </td>
                            <td column="ClientRatio">
                                {{ item.ClientRatio }}%
                            </td>
                            <td column="ExpertRatio">
                                {{ item.ExpertRatio }}%
                            </td>
                            <td column="TP">{{ item.TP }}</td>
                            <td column="SL">{{ item.SL }}</td>
                            <td column="IsReverse">
                                {{ item.IsReverse === 1 ? true : false }}
                            </td>
                            <td column="IsFollowPendingOrder">
                                {{
                                    item.IsFollowPendingOrder === 1
                                        ? true
                                        : false
                                }}
                            </td>
                            <td column="PendingRatios">
                                {{ item.PendingRatios }}
                            </td>
                            <td column="CommentPrefix">
                                {{ item.CommentPrefix }}
                            </td>
                            <td column="SymbolSuffix">
                                {{ item.SymbolSuffix }}
                            </td>
                            <td column="MinLotsTrigger">
                                {{ item.MinLotsTrigger === 1 ? true : false }}
                            </td>
                            <td column="StopOrLimit">
                                {{ item.StopOrLimit === 1 ? true : false }}
                            </td>
                            <td column="PendingPoints">
                                {{ item.PendingPoints }}
                            </td>
                        </tr>
                    </template>
                </v-data-table>
                <v-spacer></v-spacer>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="red darken-1" text @click="toEditConfig"
                        ><span>To Edit</span></v-btn
                    >
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import { helper } from "@components/mixins/helper";

export default {
    mixins: [helper],
    data() {
        return {
            expansionPanelList: [],
            showConfigDialog: false,
            apiTimer: 0,
            apiTimerValue: 0,
            timerOption: [
                { name: "off", value: 0 },
                { name: "5s", value: 5 },
                { name: "10s", value: 10 },
            ],
            apiTimeInterval: null,
        };
    },
    computed: {
        ...mapState("CopyTrade", [
            "tableHeader",
            "loading",
            "copyTradeData",
            "reasonList",
        ]),
        ...mapState("SettingsCopyTrade", [
            "loading",
            "copyTradeConfig",
            "copyTradeHeader",
        ]),
        ...mapState("SettingsServer", ["serverData"]),
        filteredHeader() {
            return (
                this.copyTradeHeader.filter(
                    (data) => data.value !== "actions"
                ) || []
            );
        },
    },
    watch: {
        copyTradeConfig(nv) {
            this.expansionPanelList = [];
            nv.map((item) => {
                this.expansionPanelList.push({
                    source: item.SourceLogin,
                    target: item.TargetLogin,
                    isExpanded: [],
                    sourceTrades: [],
                    targetTrades: [],
                });
            });
            this.getCopyTradeDataAction({ list: this.expansionPanelList });
        },
        copyTradeData(nv, ov) {
            if (JSON.stringify(nv) !== JSON.stringify(ov)) {
                nv.map((item) => {
                    const locatedPanel = this.expansionPanelList.find(
                        (list) =>
                            list.source === item.source &&
                            list.target === item.target
                    );
                    locatedPanel.sourceTrades = item.source_trades;
                    locatedPanel.targetTrades = item.target_trades.filter(
                        (data) => {
                            return (
                                data.comment.split("-")[2] ===
                                item.source.toString()
                            );
                        }
                    );
                    locatedPanel.sourceTrades.map((trade) => {
                        trade.relation =
                            locatedPanel.source + "->" + locatedPanel.target;
                    });
                    locatedPanel.targetTrades.map((trade) => {
                        trade.relation =
                            locatedPanel.source + "->" + locatedPanel.target;
                    });
                    if (
                        locatedPanel.sourceTrades.length ||
                        locatedPanel.targetTrades.length
                    ) {
                        locatedPanel.isExpanded = [0];
                    }
                });
            }
        },
        apiTimer(nv) {
            if (nv == 0) {
                clearInterval(this.apiTimeInterval);
            } else {
                clearInterval(this.apiTimeInterval);
                this.apiTimerValue = 0;
                this.apiTimeInterval = setInterval(() => {
                    if (this.apiTimerValue >= 100) {
                        this.apiTimerValue -= 100;
                        this.onRefresh();
                    } else {
                        this.apiTimerValue += 10 / this.apiTimer;
                    }
                }, 100);
            }
        },
    },
    methods: {
        ...mapActions("CopyTrade", ["getCopyTradeDataAction"]),
        ...mapActions("SettingsCopyTrade", ["getCopyTradeConfigAction"]),
        toEditConfig() {
            let routeData = this.$router.resolve({
                name: "Settings",
                query: { editing_target: "copy_trade" },
            });
            window.open(routeData.href, "_blank");
        },
        onRefresh() {
            this.getCopyTradeDataAction({ list: this.expansionPanelList });
        },
        changeTimer(timeInterval) {
            this.apiTimer = timeInterval;
        },
        sufficientTrade(data) {
            const source = data.comment.split("-")[2];
            const target = data.login.toString();
            const ticket = data.comment.split("-")[1];
            const foundTrades = this.expansionPanelList.find(
                (item) =>
                    item.target.toString() === target &&
                    item.source.toString() === source
            ).sourceTrades;
            if (foundTrades) {
                const trade = foundTrades.find(
                    (item) => item.ticket.toString() === ticket
                );
                if (trade && [0, 1, 5].includes(trade.reason)) {
                    const mode =
                        trade.reason === 0
                            ? "ClientRatio"
                            : trade.reason === 1
                            ? "ExpertRatio"
                            : "MobileRatio";

                    const config = this.copyTradeConfig.find(
                        (item) =>
                            item.SourceLogin.toString() === source &&
                            item.TargetLogin.toString() === target.toString()
                    );
                    const ratio = config[mode];
                    if (
                        (((trade.volume / 100) * ratio) / 100).toFixed(2) ===
                        (data.volume / 100).toFixed(2)
                    ) {
                        return true;
                    } else {
                        if (
                            ((trade.volume / 100) * ratio) / 100 < 0.01 &&
                            config["MinLotsTrigger"] == 1
                        ) {
                            return true;
                        } else {
                            return false;
                        }
                    }
                } else {
                    return false;
                }
            } else {
                return false;
            }
        },
        onSourceOver(item) {
            const locatedElements = document.getElementsByClassName(
                item.relation + item.ticket
            );
            const arr = [].slice.call(locatedElements);
            arr.map((item) => {
                item.style.backgroundColor = "#5D5C61";
            });
        },
        onSourceOut(item) {
            const locatedElements = document.getElementsByClassName(
                item.relation + item.ticket
            );
            const arr = [].slice.call(locatedElements);
            arr.map((item) => {
                item.style.backgroundColor = "";
            });
        },
        onTargetOver(item) {
            const locatedElements = document.getElementsByClassName(
                item.relation + item.comment.split("-")[1]
            );
            const arr = [].slice.call(locatedElements);
            arr.map((item) => (item.style.backgroundColor = "#5D5C61"));
        },
        onTargetOut(item) {
            const locatedElements = document.getElementsByClassName(
                item.relation + item.comment.split("-")[1]
            );
            const arr = [].slice.call(locatedElements);
            arr.map((item) => (item.style.backgroundColor = ""));
        },
        getServerName(serverID) {
            return (
                this.serverData.find((data) => data.id === serverID)
                    ?.server_name || "N/A"
            );
        },
        decimalPlaceCorrection(symbol, symbolPrice) {
            const numberParts = symbolPrice.toString().split(".");
            if (numberParts.length == 1) {
                return numberParts[0];
            } else {
                return numberParts[0] + "." + numberParts[1].substring(0, 2);
            }
        },
    },
    mounted() {
        this.getCopyTradeConfigAction();
    },
    destroyed() {
        clearInterval(this.apiTimeInterval);
    },
};
</script>

<style>
.fixCol_last.header {
    z-index: 4 !important;
}
.fixCol_last {
    position: sticky !important;
    right: 0;
    z-index: 1 !important;
    background-color: #1e1e1e;
}

.fixCol_first.header,
.fixCol_second.header,
.fixCol_third.header,
.fixCol_forth.header {
    z-index: 4 !important;
}
.fixCol_first {
    position: sticky !important;
    left: 0;
    z-index: 1 !important;
    background-color: #1e1e1e;
}
.fixCol_second {
    position: sticky !important;
    left: 60px;
    z-index: 1 !important;
    background-color: #1e1e1e;
}
.fixCol_third {
    position: sticky !important;
    left: 160px;
    z-index: 1 !important;
    background-color: #1e1e1e;
}
.fixCol_forth {
    position: sticky !important;
    left: 280px;
    z-index: 1 !important;
    background-color: #1e1e1e;
}
</style>
